import React from "react";

/** style */

import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { m } from "framer-motion";

import { fadeIn, fadeInLeft } from "../../Functions/GlobalAnimations";
import SocialIcons from "../SocialIcon/SocialIcons";

/** images */
import Logo from "../../../Assets/img/pnp-interprices/icons/logo.webp";
import TeckatLogo from "../../../Assets/img/pnp-interprices/icons/teckat-logo.png";

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

function Footer() {
  return (
    <>
      {/* Section Start */}
      <section className="py-[100px] md:py-[75px] sm:py-[50px] overflow-hidden">
        <Container>
          <Row className="justify-center">
            <Col xl={5} lg={4} className="md:mb-[50px] sm:mb-[30px]">
              <m.h2
                className="heading-5 font-serif font-medium text-[#333045] w-[70%] inline-block -tracking-[1px] m-0 lg:w-full md:w-[50%] xs:w-[70%]"
                {...{ ...fadeIn, transition: { delay: 0.2 } }}
              >
                Please feel free to get in touch with us
              </m.h2>
            </Col>
            <m.div
              className="col-xl-3 col-lg-4 col-sm-6 xs:mb-[30px]"
              {...{ ...fadeInLeft, transition: { delay: 0.4, duration: 1 } }}
            >
              <div className="flex">
                <div className="mr-[35px] mt-[5px]">
                  <i className="line-icon-Geo2-Love text-[32px] text-[#003271] block"></i>
                </div>
                <div className="max-w-full">
                  <span className="text-[#333045] uppercase text-md font-medium font-serif mb-[5px] block">
                    Address
                  </span>
                  <p className="m-0">Jamshedpur</p>
                </div>
              </div>
            </m.div>
            <m.div
              className="col-xl-3 col-lg-4 col-sm-6 offset-xl-1"
              {...{ ...fadeInLeft, transition: { delay: 0.6, duration: 1 } }}
            >
              <div className="flex">
                <div className="mr-[35px] mt-[5px]">
                  <i className="line-icon-Mail text-[32px] text-[#003271] block"></i>
                </div>
                <div className="max-w-full">
                  <span className="text-[#333045] uppercase text-md font-medium font-serif mb-[5px] block">
                    How can we help?
                  </span>
                  <div className="m-0">
                    <a
                      aria-label="mail"
                      href="mailto:anjani@pnpenterprises.co"
                      className="hover:text-[#003271]"
                    >
                      anjani@pnpenterprises.co
                    </a>
                    <br />

                    {/* <a
                      aria-label="link for gmail"
                      href="tel:+91 6206291947"
                      className="hover:text-[#ea9d8d]"
                    >
                      +91 6206291947
                    </a>
                    <br />
                    <a
                      aria-label="link for gmail"
                      href="tel:+91 9234510689"
                      className="hover:text-[#ea9d8d]"
                    >
                      +91 9234510689
                    </a> */}
                  </div>
                </div>
              </div>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}
      {/* Footer Start */}
      <footer className="py-[50px] border-t border-mediumgray">
        <Container>
          <Row className="items-center">
            <Col
              md={3}
              className="text-center md:text-start sm:mb-[20px] flex sm:justify-center"
            >
              <Link
                aria-label="footer home link"
                to="/"
                className="flex justify-start"
              >
                <img
                  width={111}
                  height={36}
                  className="max-h-[36px]"
                  src={Logo}
                  alt=""
                />
              </Link>
            </Col>
            <Col md={6} className="text-center md:text-end sm:mb-[20px]">
              <div className="footer_copyright text-center">
                <div>
                  © 2023 P&P Enterprises | All Rights Reserved.
                  <div className="developed_By flex justify-center ">
                    Developed By
                    <a
                      aria-label="themezaa"
                      href="https://teckat.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="flex underline underline-offset-4 text-[#003271] hover:text-[#1D2C5A] "
                    >
                      <img
                        src={TeckatLogo}
                        alt=""
                        className="w-auto h-10 mx-1"
                      />
                      Teckat.com
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <SocialIcons
                theme="social-icon-style-01"
                className="block text-right sm:text-center "
                iconColor="dark"
                size="xs"
                data={SocialIconsData}
              />
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </>
  );
}

export default Footer;
