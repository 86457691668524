import React from "react";

import "./PageNotFound.css";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="page_not_found_container">
        <div id="error-page">
          <div className="content">
            <h2 className="header" data-text="404">
              404
            </h2>
            <h4 data-text="Opps! Page not found">Opps! Page not found</h4>

            <div className="btns">
              <Link to="/">return home</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
