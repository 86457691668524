const HeaderData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Trainings",
    megamenu: [
      {
        title: "Technical",
        dropdown: [
          {
            title: "Man & Machine Interface",

            link: "/trainings/man-machine-interface",
          },
          {
            title: "Maintenance (equipment & machineries)",

            link: "/trainings/maintenance",
          },
          {
            title: "Safety ",

            link: "/trainings/safety",
          },
          {
            title: "Knowledge Worker",

            link: "/trainings/knowledge-worker",
          },
        ],
      },
      {
        title: "Non - Technical Behavioural",
        dropdown: [
          {
            title: "Align To Vision  & Mission",

            link: "/trainings/align-vision-mission",
          },
          {
            title: "Team Building",

            link: "/trainings/team-building",
          },
          {
            title: "TQM : Total Quality Management",

            link: "/trainings/tqm",
          },
          {
            title: "Motivation",

            link: "/trainings/motivation",
          },
          {
            title: "Leadership Creation",

            link: "/trainings/leadership-creation",
          },
          {
            title: "Change Management",

            link: "/trainings/change-management",
          },
          {
            title: "Risk Management ",

            link: "/trainings/risk-management",
          },
          {
            title: "Conflict Management ",

            link: "/trainings/conflict-management",
          },
          {
            title: "Employee Engagement",

            link: "/trainings/employee-engagement",
          },
          {
            title: "Negotiation",
            link: "/trainings/negotiation",
          },
          {
            title: "Joint Consultation & Industrial Relation",
            link: "/trainings/joint-consultation",
          },
        ],
      },
    ],
  },
  {
    title: "Contact",
    link: "/contact",
  },
  {
    title: "",
    link: "",
  },
];

export default HeaderData;
