import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Theme/Context/Context";

/**components */
import HeaderNavbar from "./pnpenterprises/Components/HeaderNavbar/HeaderNavbar";
import ScrollToTopButton from "./Theme/Components/ScrollToTop";
import Footer from "./pnpenterprises/Components/Footer/Footer";
import Loader from "./pnpenterprises/Components/Loader";
import {
  Tqm,
  TeamBuilding,
  Safety,
  RiskManagement,
  Negotiation,
  Motivation,
  ManMachineInterface,
  Maintenance,
  LeadershipCreation,
  KnowledgeWorker,
  JointConsultation,
  EmployeeEngagement,
  ConflictManagement,
  ChangeManagement,
  AlignVisionMission,
} from "./pnpenterprises/Pages/Trainings";
import PageNotFound from "./pnpenterprises/Components/PageNotFound/PageNotFound";

// Components
/** 
 * *: theme routes


/** pnpenterprises Pages */

const Home = lazy(() => import("./pnpenterprises/Pages/Home"));
const About = lazy(() => import("./pnpenterprises/Pages/About"));
const Contact = lazy(() => import("./pnpenterprises/Pages/Contact"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Theme/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Suspense
        fallback={
          <>
            <Loader />
          </>
        }
      >
        <GlobalContext.Provider
          value={{
            headerHeight,
            setHeaderHeight,
            footerHeight,
            setFooterHeight,
            isModalOpen,
            setIsModalOpen,
            customModal,
            setCustomModal,
          }}
        >
          <div
            className="App"
            style={{ "--header-height": `${headerHeight}px` }}
          >
            {
              <main
                style={{ marginTop: headerHeight, marginBottom: footerHeight }}
              >
                <HeaderNavbar />
                <ScrollToTopButton />
                <AnimatePresence exitBeforeEnter>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="trainings">
                      <Route path="tqm" element={<Tqm />} />
                      <Route path="team-building" element={<TeamBuilding />} />
                      <Route path="safety" element={<Safety />} />
                      <Route
                        path="risk-management"
                        element={<RiskManagement />}
                      />
                      <Route path="negotiation" element={<Negotiation />} />
                      <Route path="motivation" element={<Motivation />} />
                      <Route
                        path="man-machine-interface"
                        element={<ManMachineInterface />}
                      />
                      <Route path="maintenance" element={<Maintenance />} />
                      <Route
                        path="leadership-creation"
                        element={<LeadershipCreation />}
                      />
                      <Route
                        path="knowledge-worker"
                        element={<KnowledgeWorker />}
                      />
                      <Route
                        path="joint-consultation"
                        element={<JointConsultation />}
                      />
                      <Route
                        path="employee-engagement"
                        element={<EmployeeEngagement />}
                      />
                      <Route
                        path="conflict-management"
                        element={<ConflictManagement />}
                      />
                      <Route
                        path="change-management"
                        element={<ChangeManagement />}
                      />
                      <Route
                        path="align-vision-mission"
                        element={<AlignVisionMission />}
                      />
                    </Route>
                    <Route path="contact" element={<Contact />} />

                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </AnimatePresence>
                <Footer />
              </main>
            }
          </div>
        </GlobalContext.Provider>
      </Suspense>
    </>
  );
}

export default App;
