import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { m } from "framer-motion";

import { fadeIn } from "../../Functions/GlobalAnimations";

import { CounterDataTrainings } from "../../Components/Counters/CounterData";
import Counter from "../../Components/Counters/Counter";

// image
import counter from "../../../Assets/img/pnp-interprices/trainings/technical/machine-interface/counter.webp";

const CounterSection = () => {
  return (
    <div>
      {/* Section Start */}
      <m.section
        className="pt-[130px] lg:pt-[95px] md:pt-[75px] sm:pt-[50px] overflow-hidden"
        {...fadeIn}
      >
        <Container>
          <Row className="items-center">
            <Col md={5} className="sm:mb-[30px]">
              <h5 className="font-serif font-medium text-darkgray mb-0 sm:text-center">
                Our goal: Train over 100K professionals this year in their
                field.
              </h5>
            </Col>
            <Col lg={{ span: 6, offset: 1 }} md={7}>
              <Counter
                as="h5"
                theme="counter-style-01"
                grid="row-cols-1 row-cols-md-2 row-cols-sm-2 gap-y-10 text-[#003271]"
                // className="text-black"
                duration={7}
                data={CounterDataTrainings.slice(0, 2)}
                postfix_sign="+"
              />
            </Col>
            <Col>
              <div className="w-full h-[1px] bg-mediumgray mt-28 mb-32 sm:my-20"></div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Parallax Scrolling Start */}
      <div className="h-[600px] md:h-[450px] sm:h-[350px] bg-top overflow-hidden relative">
        <Parallax
          className="lg-no-parallax bg-cover cover-background absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh]"
          translateY={[-50, 50]}
          style={{
            backgroundImage: `url(${counter})`,
          }}
        ></Parallax>
      </div>
      {/* Parallax Scrolling End */}
    </div>
  );
};

export default CounterSection;
