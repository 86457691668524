import React from "react";

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Component
import Header, { HeaderNav, Menu, Topbar } from "../Header/Header";
import SocialIcons from "../SocialIcon/SocialIcons";
import SideButtons from "../SideButtons";
import HeaderData from "../Header/HeaderData";

/** style */
import "./HeaderNavbar.scss";

/** images */
import Logo from "../../../Assets/img/pnp-interprices/icons/logo.webp";

const SocialIconsData01 = [
  {
    color: "#ffffff99",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#ffffff99",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#ffffff99",
    link: "https://www.linkedin.com/",
    icon: "fab fa-linkedin-in",
  },
  {
    color: "#ffffff99",
    link: "https://www.youtube.com/",
    icon: "fab fa-youtube",
  },
];

function HeaderNavbar(props) {
  return (
    <>
      {/* Header Start */}
      <div className="header_nav">
        <Header
          className="header-with-topbar"
          topSpace={{ desktop: true }}
          type="reverse-scroll"
        >
          <Topbar className="bg-[#003271] text-white sm:hidden md:px-[15px]">
            <Container fluid="lg">
              <Row className="items-center justify-center">
                <Col className="col-12 col-md-3 header-social-icon d-none d-md-inline-block border-0">
                  <SocialIcons
                    theme="social-icon-style-01"
                    className="justify-start"
                    size="xs"
                    iconColor="light"
                    data={SocialIconsData01}
                  />
                </Col>
                <Col className="col-12 col-md-6 text-center px-md-0 sm-padding-5px-tb line-height-normal">
                  <span className="text-sm font-serif inline-block">
                    Involve yourself in something extraordinary.
                  </span>
                </Col>
                <Col className="col-auto col-md-3 text-right !pr-[30px] md:!pr-[15px]">
                  {/* <SearchBar className="py-[7px] pl-[20px] text-white" />
                <HeaderLanguage className="py-[7px] pl-[20px] text-white" />
                <HeaderCart
                  className="py-[7px] pl-[20px] text-white"
                  style={{ "--base-color": "#0038e3" }}
                /> */}

                  <a href="mailto:anjani@pnpenterprises.co">
                    anjani@pnpenterprises.co
                  </a>
                </Col>
              </Row>
            </Container>
          </Topbar>
          <HeaderNav
            bg="white"
            theme="light"
            containerClass="!px-0"
            className="py-[0px] md:py-[18px] md:px-[15px] sm:px-0"
          >
            <Col
              lg={6}
              xs={"auto"}
              className="px-lg-0 position-absolute left-0 right-0 mx-lg-auto text-center md:!relative mr-auto"
            >
              <Link
                aria-label="header logo"
                className="inline-block relative z-10"
                to="/"
              >
                <Navbar.Brand className="inline-block p-0 m-0 align-middle">
                  <img
                    className="default-logo"
                    width="111"
                    height="36"
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                  />
                  <img
                    className="alt-logo"
                    width="111"
                    height="36"
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                  />
                  <img
                    className="mobile-logo"
                    width="111"
                    height="36"
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                  />
                </Navbar.Brand>
              </Link>
            </Col>
            <Col className="md:flex md:justify-end md:px-[15px]">
              <Navbar.Toggle className="order-last md:ml-[17px]">
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
                <span className="navbar-toggler-line"></span>
              </Navbar.Toggle>
              <div className="hidden md:block">
                <Navbar.Collapse className="col-auto justify-center">
                  <Menu {...props} />
                </Navbar.Collapse>
              </div>
              <div className="flex justify-between md:hidden col-lg-9 col-xl-8 mx-auto !pl-[25px] !pr-[12px] lg:!pl-[15px] lg:!pr-0">
                <Menu
                  data={HeaderData.slice(0, Math.floor(HeaderData.length / 2))}
                />
                <Menu
                  data={HeaderData.slice(
                    Math.floor(HeaderData.length / 2),
                    HeaderData.length - 1
                  )}
                />
              </div>
            </Col>
          </HeaderNav>
        </Header>
      </div>
      {/* Header End */}
      <SideButtons />
    </>
  );
}

export default HeaderNavbar;
