import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { Parallax } from "react-scroll-parallax";
import { Link as ScrollTo } from "react-scroll";
import { m } from "framer-motion";

import { fadeIn } from "../../Functions/GlobalAnimations";

import Buttons from "../../Components/Button/Buttons";
// import CustomModal from "../../Components/CustomModal";
import IconWithText from "../../Components/IconWithText/IconWithText";
import CounterSection from "./CounterSection";

// image
import banner from "../../../Assets/img/pnp-interprices/trainings/non-technical/negotiation/banner.webp";
import card from "../../../Assets/img/pnp-interprices/trainings/non-technical/negotiation/card.webp";

// icon
import icon1 from "../../../Assets/img/pnp-interprices/trainings/non-technical/negotiation/icon/icon-1.webp";
import icon2 from "../../../Assets/img/pnp-interprices/trainings/non-technical/negotiation/icon/icon-2.webp";
import icon3 from "../../../Assets/img/pnp-interprices/trainings/non-technical/negotiation/icon/icon-3.webp";

const IconWithTextData = [
  {
    title: "Negotiate. Succeed",
    content:
      "learn effective negotiation techniques and tactics for professional success in our dynamic training course that we provide here.",
    img: icon1,
  },
  {
    title: "Powerful Negotiations",
    content:
      "Discover the power of effective negotiation strategies and build influential communication skills with our specialized training program.",
    img: icon2,
  },
  {
    title: "Mastery Within",
    content:
      "Embark on a journey of self-mastery in negotiation and witness personal growth alongside enhanced deal-making abilities in this training course.",
    img: icon3,
  },
];

const Negotiation = () => {
  return (
    <div>
      {/* Parallax Scrolling Start */}
      <div className="h-[660px] lg:h-[580px] md:h-[550px] sm:h-[500px] xs:h-[380px] overflow-hidden relative">
        <Parallax
          className="lg-no-parallax h-[100vh] w-full bg-cover bg-top cover-background absolute top-[0px] left-0 lg:h-[700px] lg:-top-[100px] md:h-[650px] sm:h-[550px] xs:h-[420px] xs:-top-[70px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url(${banner})`,
          }}
        ></Parallax>
        <div className="absolute h-full w-full opacity-50 top-0 left-0 bg-darkgray"></div>
        <Container className="h-full relative xs:px-[15px]">
          <Row className="justify-center h-full">
            <Col
              xl={6}
              lg={7}
              md={8}
              className="relative font-serif text-center text-white flex justify-center flex-col"
            >
              <h1 className="opacity-60 mb-[20px] text-xmd leading-[20px]">
                NEGOTIATION TRAINING
              </h1>
              <h2 className="font-medium -tracking-[1px] mb-0">
                Negotiate with Confidence, Master Success
              </h2>
            </Col>
            <ScrollTo
              to="about"
              offset={0}
              delay={0}
              spy={true}
              smooth={true}
              duration={800}
              className="absolute bottom-[50px] left-1/2 w-auto inline-block -translate-x-1/2 cursor-pointer"
            >
              <i className="ti-arrow-down text-lg leading-none text-white bg-[#003271] p-[15px] xs:p-[10px] rounded-full flex justify-center items-center"></i>
            </ScrollTo>
          </Row>
        </Container>
      </div>
      {/* Parallax Scrolling End */}

      {/* Section Start */}
      <m.section
        id="about"
        className="py-[130px] lg:py-[95px] md:py-[75px] sm:py-[50px] overflow-hidden"
        {...fadeIn}
      >
        <Container>
          <Row className="items-center">
            <Col md={6} className="relative sm:mb-[30px]">
              <img
                src={card}
                className="rounded-[5px] w-full"
                height="564"
                width="390"
                alt="what-we-offers"
              />
              {/* Modal Component Start */}
              {/* <CustomModal.Wrapper
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
                modalBtn={
                  <Buttons
                    type="submit"
                    className="btn-sonar border-0"
                    themeColor={["#b27cfd", "#ff9393"]}
                    color="#fff"
                    size="lg"
                    title={<i className="icon-control-play" />}
                  />
                }
              >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
                    <iframe
                      width="100%"
                      height="100%"
                      className="shadow-[0_0_8px_rgba(0,0,0,0.06)]"
                      controls
                      src="https://www.youtube.com/embed/g0f_BRYJLJE?autoplay=1"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper> */}
              {/* Modal Component End */}
            </Col>
            <Col lg={{ span: 5, offset: 1 }} md={6}>
              <h5 className="font-serif font-medium text-darkgray w-[90%]">
                Unlock the Art of Negotiation Today
              </h5>
              <p className="w-[85%] mb-[25px] lg:w-[90%]">
                Our comprehensive negotiation training course equips you with
                essential strategies, communication skills, and confidence to
                achieve winning outcomes in any negotiation scenario.
              </p>
              <div className="mt-[15px] sm:mt-0 inline-block">
                <Buttons
                  to="/contact"
                  className="mr-[24px] font-medium rounded-none font-serif uppercase text-[#fff] hover:border-[#003271] hover:text-[#003271] btn-slide-filling-right bg-gradient-to-r from-[#003271] to-[#003271] sm:mr-[20px] xs:mr-[8px]"
                  size="md"
                  themeColor="#fff"
                  color="#fff"
                  title="Connect us"
                />
                <Buttons
                  to="/about"
                  className="font-medium rounded-none font-serif uppercase text-[#003271] hover:text-white bg-transparent btn-slide-right"
                  size="md"
                  color="#003271"
                  themeColor="#003271"
                  title="More About Us"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

      {/* Section Start */}
      <section className="py-[130px] lg:py-[95px] md:py-[75px] sm:py-[50px] xs:px-[15px] bg-lightgray">
        <Container>
          <IconWithText
            grid="row-cols-1 row-cols-lg-3 row-cols-sm-2"
            theme="icon-with-text-10"
            data={IconWithTextData}
            animation={fadeIn}
            animationDelay={0.1}
          />
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <CounterSection />
      {/* Section End */}
    </div>
  );
};

export default Negotiation;
